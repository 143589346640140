import {
  CreateDIV,
  CreateImage,
  CreateInputButton,
  MouseActionType,
  createElement,
  CreateInputCheck,
  CreateLabel,
  CreateSPAN,
} from '../HTML';

import { getLanguageString, xdr, downloadLegalDocument } from '../common';

import { save_websession, websession } from '../websession';
import { lang } from '../language';

export const legalDocumentsCheckDoneEvent = new Event(
  'onLegalDocumentsCheckDone',
  {
    bubbles: true,
    cancelable: false,
  }
);

// **** COMPONENTS
const Components = window.Components ?? {};

// **** DIALOG
/**
 * @namespace Components.Dialog
 */
Components.Dialog = Components.Dialog ?? {};

/**
 * A modal dialog type
 * @typedef {(number|string)} ModalDialogType
 * @memberof Components.Dialog
 */
export const ModalDialogType = {};

ModalDialogType[(ModalDialogType['NotificationOk'] = 0)] = 'NotificationOk';
ModalDialogType[(ModalDialogType['NotificationSucces'] = 1)] =
  'NotificationSucces';
ModalDialogType[(ModalDialogType['NotificationFail'] = 2)] = 'NotificationFail';
ModalDialogType[(ModalDialogType['Confirmation'] = 3)] = 'Confirmation';
ModalDialogType[(ModalDialogType['Busy'] = 4)] = 'Busy';
ModalDialogType[(ModalDialogType['OK'] = 5)] = 'OK';
ModalDialogType[(ModalDialogType['Cookie'] = 6)] = 'Cookie';
ModalDialogType[(ModalDialogType['LegalDocuments'] = 7)] = 'LegalDocuments';
ModalDialogType[(ModalDialogType['DatePicker'] = 8)] = 'DatePicker';
ModalDialogType[(ModalDialogType['Duration'] = 9)] = 'Duration';
ModalDialogType[(ModalDialogType['HTML'] = 10)] = 'HTML';

/**
 * Close active dialog
 * @method CloseACtiveDialog
 * @memberof Components.Dialog
 */
var activeDialog = null;
function CloseACtiveDialog() {
  activeDialog.close();
}
Components.Dialog.CloseACtiveDialog = CloseACtiveDialog;

export function ModalDialog(
  Owner,
  Type,
  DialogText,
  ConfirmButtonText,
  OnConfirm,
  CancelButtonText,
  OnCancel,
  Data
) {
  var _self = this;
  var divButtonConfirm;
  var elI;

  var divDialog = CreateDIV(Owner, null, null, 'openModal');
  divDialog.style.opacity = 1;
  divDialog.style.pointerEvents = 'auto';

  var divDialogContent = CreateDIV(divDialog, 'dialog');

  // NOTIFICATIONOK
  if (Type === ModalDialogType.NotificationOk) {
    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonConfirm,
      'confirm',
      function (a, e) {
        if (a === MouseActionType.Click) {
          _self.close();
        }
      },
      ConfirmButtonText
    );
  }
  //NOTIFICATIONSUCCES
  else if (Type === ModalDialogType.NotificationSucces) {
    var pathToImg = '/content_media/';
    var src = 'dialog_icon_succes.gif';
    var container = CreateDIV(
      divDialogContent,
      'new-image-container',
      'width:100%;display:flex;justify-content:center;align-items:center;margin-top:25px',
      null,
      null
    );
    CreateImage(
      container,
      pathToImg + src,
      null,
      null,
      null,
      'width:250px;height:160px'
    );

    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonConfirm,
      'green',
      function (a, e) {
        if (a === MouseActionType.Click) {
          _self.close();
        }
      },
      ConfirmButtonText
    );
  }
  //NOTIFICATIONFAIL
  else if (Type === ModalDialogType.NotificationFail) {
    var confirm =
      OnConfirm === null
        ? function () {
            close();
          }
        : OnConfirm;

    var pathToImg = '/content_media/';
    var src = 'dialog_icon_fail.gif';
    var container = CreateDIV(
      divDialogContent,
      'new-image-container',
      'width:100%;display:flex;justify-content:center;align-items:center;margin-top:25px',
      null,
      null
    );
    CreateImage(
      container,
      pathToImg + src,
      null,
      null,
      null,
      'width:250px;height:160px'
    );

    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonConfirm,
      'green',
      function (a, e) {
        if (a === MouseActionType.Click) {
          _self.close();
          if (confirm !== undefined) {
            confirm();
          }
        }
      },
      ConfirmButtonText
    );
  }
  //CONFIRMATION
  else if (Type === ModalDialogType.Confirmation) {
    var confirm =
      OnConfirm === null
        ? function () {
            close();
          }
        : OnConfirm;

    var pathToImg = '/content_media/';
    var src = 'dialog_icon_confirmation.gif';
    var container = CreateDIV(
      divDialogContent,
      'new-image-container',
      'width:100%;display:flex;justify-content:center;align-items:center;margin-top:25px',
      null,
      null
    );
    CreateImage(
      container,
      pathToImg + src,
      null,
      null,
      null,
      'width:250px;height:160px'
    );
    //elI = createElement("i", null, "fa fa-exclamation-circle orange");
    //CreateDIV(divDialogContent, "dialog-icon", null, null, elI.outerHTML);

    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    var divButtonCancel = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonCancel,
      'grey',
      function (a, e) {
        if (a === MouseActionType.Click) {
          _self.close();
        }
      },
      CancelButtonText
    );

    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonConfirm,
      'green',
      function (a, e) {
        if (a === MouseActionType.Click) {
          setTimeout(function () {
            _self.close();
            confirm();
          }, 100);
        }
      },
      ConfirmButtonText
    );
  }
  //BUSY
  else if (Type === ModalDialogType.Busy) {
    elI = createElement(
      'svg',
      null,
      'Loading-icon fa-spin',
      `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="rgba(90,30,236,0.3)" stroke-width="4" />
  <path fill="rgba(90,30,236)"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
</svg>`
    );

    elI.style.width = '64px';
    elI.style.height = '64px';
    elI.style.paddingTop = '0px';
    CreateDIV(divDialogContent, 'loader-icon blue', null, null, elI.outerHTML);
    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    if (OnConfirm !== null && OnConfirm !== undefined) {
      OnConfirm();
    }
  }
  //OK
  else if (Type === ModalDialogType.OK) {
    var pathToImg = '/content_media/';
    var src = 'dialog_icon_succes.gif';
    var container = CreateDIV(
      divDialogContent,
      'new-image-container',
      'width:100%;display:flex;justify-content:center;align-items:center;margin-top:25px',
      null,
      null
    );
    CreateImage(
      container,
      pathToImg + src,
      null,
      null,
      null,
      'width:250px;height:160px'
    );

    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    OnConfirm();
  }
  //COOKIE
  else if (Type === ModalDialogType.Cookie) {
    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonConfirm,
      'green',
      function (a, e) {
        if (a === MouseActionType.Click) {
          OnConfirm();
          _self.close();
        }
      },
      ConfirmButtonText
    );
  }
  //LEGAL DOCUMENTS
  else if (Type === ModalDialogType.LegalDocuments) {
    var confirmFunction =
      OnConfirm === null
        ? function () {
            save_websession();
          }
        : OnConfirm;

    var counter = 0;

    var dialogContent = CreateDIV(
      divDialogContent,
      'dialog-txt',
      null,
      null,
      DialogText
    );

    var isTurkishCustomer =
      websession.CurrentUser && websession.CurrentUser.Country === 'TR';
    if (isTurkishCustomer) {
      CreateSPAN(
        dialogContent,
        null,
        null,
        '<br/><br/>' + lang.generic.dlg_txt_turkish_legal_consent,
        null
      );
    }

    // DIALOG CGECKBOX CONTAINER
    var divCBWrapper = CreateDIV(divDialogContent, 'cb-wrapper');

    //LOOP THROUGH DOCUMENTS
    var cbArray = [];
    Data.forEach(function (d) {
      // CHECK IF DOCUMENT MUST BE CONFIRMED
      if (d.CheckId === 0) {
        var divCBContainer = CreateDIV(divCBWrapper, 'cb-dialog-container');

        var divCB = CreateDIV(divCBContainer, 'cb-dialog');
        var lblCB = CreateLabel(divCB, 'checkbox');
        var cb = CreateInputCheck(
          lblCB,
          null,
          function (a, e) {
            var c = cb;
            if (a === MouseActionType.Click) {
              c.checked ? counter++ : counter--;

              buttonLD.className =
                cbArray.length === counter ? 'green' : 'disabled';
              buttonLD.disabled = cbArray.length !== counter;
            }
          },
          null,
          null,
          null,
          'cb_' + d.TypeId
        );
        CreateSPAN(lblCB);

        cbArray.push(cb);

        var spanFile = CreateSPAN(
          divCB,
          null,
          null,
          getLanguageString(d.TypeName),
          function (a, e) {
            if (a === MouseActionType.Click) {
              downloadLegalDocument(d);
            }
          }
        );
        spanFile.style.textDecoration = 'underline';
      }
    });

    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    var buttonLD = CreateInputButton(
      divButtonConfirm,
      'disabled',
      function (a, e) {
        if (a === MouseActionType.Click && cbArray.length === counter) {
          checkLegalDocument(Data);
        }
      },
      ConfirmButtonText
    );
    buttonLD.disabled = true;

    // CONFIRM CHECK TO API
    var cldCounter = 0;
    var checkLegalDocument = function (data) {
      // LEGAL DOCUMENT IS CHECKED
      if (data[cldCounter].CheckId !== 0) {
        cldCounter++;
        if (cldCounter < data.length) {
          checkLegalDocument(data);
        } else {
          confirmFunction();
          _self.close();
        }
      } else {
        xdr(
          ghapi + '/API/CompanyDocument/' + data[cldCounter].TypeId,
          'PUT',
          null,
          function (x) {
            // MODIFY WEBSESSION
            var newId = JSON.parse(x);

            data.forEach(function (ld) {
              if (ld) {
                if (ld.TypeId === data[cldCounter].TypeId) {
                  ld.CheckId = newId.Id;
                }
              }
            });

            // LOOP THROUGH DOCUMENTS
            cldCounter++;
            if (cldCounter < data.length) {
              checkLegalDocument(data);
            } else {
              confirmFunction();
              _self.close();
            }
          },
          function (x) {
            _self.close();
            new Components.Dialog.ModalDialog(
              document.body,
              ModalDialogType.NotificationFail,
              lang.generic.err_dlg_text + x,
              lang.generic.err_dlg_btn
            );
          }
        );
      }
      window.dispatchEvent(legalDocumentsCheckDoneEvent);
    };
  }
  // DATE PICKER
  else if (Type === ModalDialogType.DatePicker) {
    var confirm =
      OnConfirm === null
        ? function () {
            close();
          }
        : OnConfirm;

    DateLabels = {
      ShortDayLabels: lang.generic.weekdays_short.split(','),
      FullDayLabels: lang.generic.weekdays.split(','),
      ShortMonthLabels: lang.generic.monthnames_short.split(','),
      FullMonthLabels: lang.generic.monthnames.split(','),
    };

    var sd = new Date();

    var divContainer = CreateDIV(divDialogContent);

    var dateFrom = new Components.Filter.DatePicker(
      divContainer,
      'dateFrom',
      'test',
      sd,
      DateLabels,
      lang.generic.date_format,
      filter,
      function (a, e) {
        Data.StartDate = dateFrom.value();
        confirm();
        _self.close();
      }
    );

    setTimeout(function () {
      dateFrom.initialize();
      dateFrom.show();
      document
        .getElementById('openModal')
        .addEventListener('click', function (ev) {
          _self.close();
        });
    });
  }
  // HTML CONTENT
  else if (Type === ModalDialogType.HTML) {
    var confirm =
      OnConfirm === null
        ? function () {
            close();
          }
        : OnConfirm;

    // ADD DIALOG TEXT
    CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

    // ADD DIALOG INNERHTML
    divDialogContent.appendChild(Data);

    // BUTTON CANCEL
    var divButtonCancel = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonCancel,
      'grey',
      function (a, e) {
        if (a === MouseActionType.Click) {
          _self.close();
        }
      },
      CancelButtonText
    );

    // BUTTON CONFIRM
    divButtonConfirm = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButtonConfirm,
      'green',
      function (a, e) {
        if (a === MouseActionType.Click) {
          confirm();
        }
      },
      ConfirmButtonText
    );
  }

  this.close = function () {
    activeDialog = null;
    if (divDialog.parentElement) {
      divDialog.parentElement.removeChild(divDialog);
    }
  };

  activeDialog = this;
}
Components.Dialog.ModalDialog = ModalDialog;

/**
 * Create a modal dialog with multiple buttons
 * @class MultipleButtonDialog
 * @memberof Components.Dialog
 * @param {object} Owner - HTML DOM element
 * @param {string} DialogText - Text in dialog
 * @param {object[]} Buttons - Array with button function and text value of button
 */
// DIALOG WITH A VARIABLE NUMBER OF BUTONS
export function MultipleButtonDialog(Owner, DialogText, Buttons) {
  var _self = this;
  var divButtonConfirm;
  var elI;

  // CONTAINER
  var divDialog = CreateDIV(Owner, null, null, 'openModal');
  divDialog.style.opacity = 1;
  divDialog.style.pointerEvents = 'auto';

  // CONTENT
  var divDialogContent = CreateDIV(divDialog, 'dialog');

  // ADD DIALOG TEXT
  CreateDIV(divDialogContent, 'dialog-txt', null, null, DialogText);

  // ADD BUTTONS
  Buttons.forEach(function (b) {
    var divButton = CreateDIV(divDialogContent, 'dialog-btn');
    CreateInputButton(
      divButton,
      b.Class,
      function (a, e) {
        if (a === MouseActionType.Click) {
          if (b.Function !== null) {
            b.Function();
          }
          _self.close();
        }
      },
      b.Value
    );
  });

  // PUBLIC FUNCTIONS
  /**
   * Close dialog
   * @method close
   * @memberof Components.Dialog.MultipleButtonDialog#
   */
  this.close = function () {
    activeDialog = null;
    if (divDialog.parentElement) {
      divDialog.parentElement.removeChild(divDialog);
    }
  };

  activeDialog = this;
}
//COMPATIBILITY
Components.Dialog.MultipleButtonDialog = MultipleButtonDialog;
Components.Dialog.ModalDialogType = ModalDialogType;

window.Components = Components;
