import { LanguageCode } from '../models/Language';
import { edit_url } from './client';
import { getWebsession } from './websession';
import { addError } from '../error-capturing';
import { getToken } from '../utils/sessionUtils';

var Latinise = {};
Latinise.latin_map = {
  '¡': '-',
  '¿': '-',
  '»': '-',
  '«': '-',
  '’': '-',
  '“': '-',
  '”': '-',
  '#': '-',
  "'": '-',
  ',': '-',
  ':': '-',
  ß: 'ss',
  '!': '-',
  '(': '-',
  ')': '-',
  ' ': '-',
  Á: 'A',
  Ă: 'A',
  Ắ: 'A',
  Ặ: 'A',
  Ằ: 'A',
  Ẳ: 'A',
  Ẵ: 'A',
  Ǎ: 'A',
  Â: 'A',
  Ấ: 'A',
  Ậ: 'A',
  Ầ: 'A',
  Ẩ: 'A',
  Ẫ: 'A',
  Ä: 'A',
  Ǟ: 'A',
  Ȧ: 'A',
  Ǡ: 'A',
  Ạ: 'A',
  Ȁ: 'A',
  À: 'A',
  Ả: 'A',
  Ȃ: 'A',
  Ā: 'A',
  Ą: 'A',
  Å: 'A',
  Ǻ: 'A',
  Ḁ: 'A',
  Ⱥ: 'A',
  Ã: 'A',
  Ꜳ: 'AA',
  Æ: 'AE',
  Ǽ: 'AE',
  Ǣ: 'AE',
  Ꜵ: 'AO',
  Ꜷ: 'AU',
  Ꜹ: 'AV',
  Ꜻ: 'AV',
  Ꜽ: 'AY',
  Ḃ: 'B',
  Ḅ: 'B',
  Ɓ: 'B',
  Ḇ: 'B',
  Ƀ: 'B',
  Ƃ: 'B',
  Ć: 'C',
  Č: 'C',
  Ç: 'C',
  Ḉ: 'C',
  Ĉ: 'C',
  Ċ: 'C',
  Ƈ: 'C',
  Ȼ: 'C',
  Ď: 'D',
  Ḑ: 'D',
  Ḓ: 'D',
  Ḋ: 'D',
  Ḍ: 'D',
  Ɗ: 'D',
  Ḏ: 'D',
  ǲ: 'D',
  ǅ: 'D',
  Đ: 'D',
  Ƌ: 'D',
  Ǳ: 'DZ',
  Ǆ: 'DZ',
  É: 'E',
  Ĕ: 'E',
  Ě: 'E',
  Ȩ: 'E',
  Ḝ: 'E',
  Ê: 'E',
  Ế: 'E',
  Ệ: 'E',
  Ề: 'E',
  Ể: 'E',
  Ễ: 'E',
  Ḙ: 'E',
  Ë: 'E',
  Ė: 'E',
  Ẹ: 'E',
  Ȅ: 'E',
  È: 'E',
  Ẻ: 'E',
  Ȇ: 'E',
  Ē: 'E',
  Ḗ: 'E',
  Ḕ: 'E',
  Ę: 'E',
  Ɇ: 'E',
  Ẽ: 'E',
  Ḛ: 'E',
  Ꝫ: 'ET',
  Ḟ: 'F',
  Ƒ: 'F',
  Ǵ: 'G',
  Ğ: 'G',
  Ǧ: 'G',
  Ģ: 'G',
  Ĝ: 'G',
  Ġ: 'G',
  Ɠ: 'G',
  Ḡ: 'G',
  Ǥ: 'G',
  Ḫ: 'H',
  Ȟ: 'H',
  Ḩ: 'H',
  Ĥ: 'H',
  Ⱨ: 'H',
  Ḧ: 'H',
  Ḣ: 'H',
  Ḥ: 'H',
  Ħ: 'H',
  Í: 'I',
  Ĭ: 'I',
  Ǐ: 'I',
  Î: 'I',
  Ï: 'I',
  Ḯ: 'I',
  İ: 'I',
  Ị: 'I',
  Ȉ: 'I',
  Ì: 'I',
  Ỉ: 'I',
  Ȋ: 'I',
  Ī: 'I',
  Į: 'I',
  Ɨ: 'I',
  Ĩ: 'I',
  Ḭ: 'I',
  Ꝺ: 'D',
  Ꝼ: 'F',
  Ᵹ: 'G',
  Ꞃ: 'R',
  Ꞅ: 'S',
  Ꞇ: 'T',
  Ꝭ: 'IS',
  Ĵ: 'J',
  Ɉ: 'J',
  Ḱ: 'K',
  Ǩ: 'K',
  Ķ: 'K',
  Ⱪ: 'K',
  Ꝃ: 'K',
  Ḳ: 'K',
  Ƙ: 'K',
  Ḵ: 'K',
  Ꝁ: 'K',
  Ꝅ: 'K',
  Ĺ: 'L',
  Ƚ: 'L',
  Ľ: 'L',
  Ļ: 'L',
  Ḽ: 'L',
  Ḷ: 'L',
  Ḹ: 'L',
  Ⱡ: 'L',
  Ꝉ: 'L',
  Ḻ: 'L',
  Ŀ: 'L',
  Ɫ: 'L',
  ǈ: 'L',
  Ł: 'L',
  Ǉ: 'LJ',
  Ḿ: 'M',
  Ṁ: 'M',
  Ṃ: 'M',
  Ɱ: 'M',
  Ń: 'N',
  Ň: 'N',
  Ņ: 'N',
  Ṋ: 'N',
  Ṅ: 'N',
  Ṇ: 'N',
  Ǹ: 'N',
  Ɲ: 'N',
  Ṉ: 'N',
  Ƞ: 'N',
  ǋ: 'N',
  Ñ: 'N',
  Ǌ: 'NJ',
  Ó: 'O',
  Ŏ: 'O',
  Ǒ: 'O',
  Ô: 'O',
  Ố: 'O',
  Ộ: 'O',
  Ồ: 'O',
  Ổ: 'O',
  Ỗ: 'O',
  Ö: 'O',
  Ȫ: 'O',
  Ȯ: 'O',
  Ȱ: 'O',
  Ọ: 'O',
  Ő: 'O',
  Ȍ: 'O',
  Ò: 'O',
  Ỏ: 'O',
  Ơ: 'O',
  Ớ: 'O',
  Ợ: 'O',
  Ờ: 'O',
  Ở: 'O',
  Ỡ: 'O',
  Ȏ: 'O',
  Ꝋ: 'O',
  Ꝍ: 'O',
  Ō: 'O',
  Ṓ: 'O',
  Ṑ: 'O',
  Ɵ: 'O',
  Ǫ: 'O',
  Ǭ: 'O',
  Ø: 'O',
  Ǿ: 'O',
  Õ: 'O',
  Ṍ: 'O',
  Ṏ: 'O',
  Ȭ: 'O',
  Ƣ: 'OI',
  Ꝏ: 'OO',
  Ɛ: 'E',
  Ɔ: 'O',
  Ȣ: 'OU',
  Ṕ: 'P',
  Ṗ: 'P',
  Ꝓ: 'P',
  Ƥ: 'P',
  Ꝕ: 'P',
  Ᵽ: 'P',
  Ꝑ: 'P',
  Ꝙ: 'Q',
  Ꝗ: 'Q',
  Ŕ: 'R',
  Ř: 'R',
  Ŗ: 'R',
  Ṙ: 'R',
  Ṛ: 'R',
  Ṝ: 'R',
  Ȑ: 'R',
  Ȓ: 'R',
  Ṟ: 'R',
  Ɍ: 'R',
  Ɽ: 'R',
  Ꜿ: 'C',
  Ǝ: 'E',
  Ś: 'S',
  Ṥ: 'S',
  Š: 'S',
  Ṧ: 'S',
  Ş: 'S',
  Ŝ: 'S',
  Ș: 'S',
  Ṡ: 'S',
  Ṣ: 'S',
  Ṩ: 'S',
  Ť: 'T',
  Ţ: 'T',
  Ṱ: 'T',
  Ț: 'T',
  Ⱦ: 'T',
  Ṫ: 'T',
  Ṭ: 'T',
  Ƭ: 'T',
  Ṯ: 'T',
  Ʈ: 'T',
  Ŧ: 'T',
  Ɐ: 'A',
  Ꞁ: 'L',
  Ɯ: 'M',
  Ʌ: 'V',
  Ꜩ: 'TZ',
  Ú: 'U',
  Ŭ: 'U',
  Ǔ: 'U',
  Û: 'U',
  Ṷ: 'U',
  Ü: 'U',
  Ǘ: 'U',
  Ǚ: 'U',
  Ǜ: 'U',
  Ǖ: 'U',
  Ṳ: 'U',
  Ụ: 'U',
  Ű: 'U',
  Ȕ: 'U',
  Ù: 'U',
  Ủ: 'U',
  Ư: 'U',
  Ứ: 'U',
  Ự: 'U',
  Ừ: 'U',
  Ử: 'U',
  Ữ: 'U',
  Ȗ: 'U',
  Ū: 'U',
  Ṻ: 'U',
  Ų: 'U',
  Ů: 'U',
  Ũ: 'U',
  Ṹ: 'U',
  Ṵ: 'U',
  Ꝟ: 'V',
  Ṿ: 'V',
  Ʋ: 'V',
  Ṽ: 'V',
  Ꝡ: 'VY',
  Ẃ: 'W',
  Ŵ: 'W',
  Ẅ: 'W',
  Ẇ: 'W',
  Ẉ: 'W',
  Ẁ: 'W',
  Ⱳ: 'W',
  Ẍ: 'X',
  Ẋ: 'X',
  Ý: 'Y',
  Ŷ: 'Y',
  Ÿ: 'Y',
  Ẏ: 'Y',
  Ỵ: 'Y',
  Ỳ: 'Y',
  Ƴ: 'Y',
  Ỷ: 'Y',
  Ỿ: 'Y',
  Ȳ: 'Y',
  Ɏ: 'Y',
  Ỹ: 'Y',
  Ź: 'Z',
  Ž: 'Z',
  Ẑ: 'Z',
  Ⱬ: 'Z',
  Ż: 'Z',
  Ẓ: 'Z',
  Ȥ: 'Z',
  Ẕ: 'Z',
  Ƶ: 'Z',
  Ĳ: 'IJ',
  Œ: 'OE',
  ᴀ: 'A',
  ᴁ: 'AE',
  ʙ: 'B',
  ᴃ: 'B',
  ᴄ: 'C',
  ᴅ: 'D',
  ᴇ: 'E',
  ꜰ: 'F',
  ɢ: 'G',
  ʛ: 'G',
  ʜ: 'H',
  ɪ: 'I',
  ʁ: 'R',
  ᴊ: 'J',
  ᴋ: 'K',
  ʟ: 'L',
  ᴌ: 'L',
  ᴍ: 'M',
  ɴ: 'N',
  ᴏ: 'O',
  ɶ: 'OE',
  ᴐ: 'O',
  ᴕ: 'OU',
  ᴘ: 'P',
  ʀ: 'R',
  ᴎ: 'N',
  ᴙ: 'R',
  ꜱ: 'S',
  ᴛ: 'T',
  ⱻ: 'E',
  ᴚ: 'R',
  ᴜ: 'U',
  ᴠ: 'V',
  ᴡ: 'W',
  ʏ: 'Y',
  ᴢ: 'Z',
  á: 'a',
  ă: 'a',
  ắ: 'a',
  ặ: 'a',
  ằ: 'a',
  ẳ: 'a',
  ẵ: 'a',
  ǎ: 'a',
  â: 'a',
  ấ: 'a',
  ậ: 'a',
  ầ: 'a',
  ẩ: 'a',
  ẫ: 'a',
  ä: 'a',
  ǟ: 'a',
  ȧ: 'a',
  ǡ: 'a',
  ạ: 'a',
  ȁ: 'a',
  à: 'a',
  ả: 'a',
  ȃ: 'a',
  ā: 'a',
  ą: 'a',
  ᶏ: 'a',
  ẚ: 'a',
  å: 'a',
  ǻ: 'a',
  ḁ: 'a',
  ⱥ: 'a',
  ã: 'a',
  ꜳ: 'aa',
  æ: 'ae',
  ǽ: 'ae',
  ǣ: 'ae',
  ꜵ: 'ao',
  ꜷ: 'au',
  ꜹ: 'av',
  ꜻ: 'av',
  ꜽ: 'ay',
  ḃ: 'b',
  ḅ: 'b',
  ɓ: 'b',
  ḇ: 'b',
  ᵬ: 'b',
  ᶀ: 'b',
  ƀ: 'b',
  ƃ: 'b',
  ɵ: 'o',
  ć: 'c',
  č: 'c',
  ç: 'c',
  ḉ: 'c',
  ĉ: 'c',
  ɕ: 'c',
  ċ: 'c',
  ƈ: 'c',
  ȼ: 'c',
  ď: 'd',
  ḑ: 'd',
  ḓ: 'd',
  ȡ: 'd',
  ḋ: 'd',
  ḍ: 'd',
  ɗ: 'd',
  ᶑ: 'd',
  ḏ: 'd',
  ᵭ: 'd',
  ᶁ: 'd',
  đ: 'd',
  ɖ: 'd',
  ƌ: 'd',
  ı: 'i',
  ȷ: 'j',
  ɟ: 'j',
  ʄ: 'j',
  ǳ: 'dz',
  ǆ: 'dz',
  é: 'e',
  ĕ: 'e',
  ě: 'e',
  ȩ: 'e',
  ḝ: 'e',
  ê: 'e',
  ế: 'e',
  ệ: 'e',
  ề: 'e',
  ể: 'e',
  ễ: 'e',
  ḙ: 'e',
  ë: 'e',
  ė: 'e',
  ẹ: 'e',
  ȅ: 'e',
  è: 'e',
  ẻ: 'e',
  ȇ: 'e',
  ē: 'e',
  ḗ: 'e',
  ḕ: 'e',
  ⱸ: 'e',
  ę: 'e',
  ᶒ: 'e',
  ɇ: 'e',
  ẽ: 'e',
  ḛ: 'e',
  ꝫ: 'et',
  ḟ: 'f',
  ƒ: 'f',
  ᵮ: 'f',
  ᶂ: 'f',
  ǵ: 'g',
  ğ: 'g',
  ǧ: 'g',
  ģ: 'g',
  ĝ: 'g',
  ġ: 'g',
  ɠ: 'g',
  ḡ: 'g',
  ᶃ: 'g',
  ǥ: 'g',
  ḫ: 'h',
  ȟ: 'h',
  ḩ: 'h',
  ĥ: 'h',
  ⱨ: 'h',
  ḧ: 'h',
  ḣ: 'h',
  ḥ: 'h',
  ɦ: 'h',
  ẖ: 'h',
  ħ: 'h',
  ƕ: 'hv',
  í: 'i',
  ĭ: 'i',
  ǐ: 'i',
  î: 'i',
  ï: 'i',
  ḯ: 'i',
  ị: 'i',
  ȉ: 'i',
  ì: 'i',
  ỉ: 'i',
  ȋ: 'i',
  ī: 'i',
  į: 'i',
  ᶖ: 'i',
  ɨ: 'i',
  ĩ: 'i',
  ḭ: 'i',
  ꝺ: 'd',
  ꝼ: 'f',
  ᵹ: 'g',
  ꞃ: 'r',
  ꞅ: 's',
  ꞇ: 't',
  ꝭ: 'is',
  ǰ: 'j',
  ĵ: 'j',
  ʝ: 'j',
  ɉ: 'j',
  ḱ: 'k',
  ǩ: 'k',
  ķ: 'k',
  ⱪ: 'k',
  ꝃ: 'k',
  ḳ: 'k',
  ƙ: 'k',
  ḵ: 'k',
  ᶄ: 'k',
  ꝁ: 'k',
  ꝅ: 'k',
  ĺ: 'l',
  ƚ: 'l',
  ɬ: 'l',
  ľ: 'l',
  ļ: 'l',
  ḽ: 'l',
  ȴ: 'l',
  ḷ: 'l',
  ḹ: 'l',
  ⱡ: 'l',
  ꝉ: 'l',
  ḻ: 'l',
  ŀ: 'l',
  ɫ: 'l',
  ᶅ: 'l',
  ɭ: 'l',
  ł: 'l',
  ǉ: 'lj',
  ſ: 's',
  ẜ: 's',
  ẛ: 's',
  ẝ: 's',
  ḿ: 'm',
  ṁ: 'm',
  ṃ: 'm',
  ɱ: 'm',
  ᵯ: 'm',
  ᶆ: 'm',
  ń: 'n',
  ň: 'n',
  ņ: 'n',
  ṋ: 'n',
  ȵ: 'n',
  ṅ: 'n',
  ṇ: 'n',
  ǹ: 'n',
  ɲ: 'n',
  ṉ: 'n',
  ƞ: 'n',
  ᵰ: 'n',
  ᶇ: 'n',
  ɳ: 'n',
  ñ: 'n',
  ǌ: 'nj',
  ó: 'o',
  ŏ: 'o',
  ǒ: 'o',
  ô: 'o',
  ố: 'o',
  ộ: 'o',
  ồ: 'o',
  ổ: 'o',
  ỗ: 'o',
  ö: 'o',
  ȫ: 'o',
  ȯ: 'o',
  ȱ: 'o',
  ọ: 'o',
  ő: 'o',
  ȍ: 'o',
  ò: 'o',
  ỏ: 'o',
  ơ: 'o',
  ớ: 'o',
  ợ: 'o',
  ờ: 'o',
  ở: 'o',
  ỡ: 'o',
  ȏ: 'o',
  ꝋ: 'o',
  ꝍ: 'o',
  ⱺ: 'o',
  ō: 'o',
  ṓ: 'o',
  ṑ: 'o',
  ǫ: 'o',
  ǭ: 'o',
  ø: 'o',
  ǿ: 'o',
  õ: 'o',
  ṍ: 'o',
  ṏ: 'o',
  ȭ: 'o',
  ƣ: 'oi',
  ꝏ: 'oo',
  ɛ: 'e',
  ᶓ: 'e',
  ɔ: 'o',
  ᶗ: 'o',
  ȣ: 'ou',
  ṕ: 'p',
  ṗ: 'p',
  ꝓ: 'p',
  ƥ: 'p',
  ᵱ: 'p',
  ᶈ: 'p',
  ꝕ: 'p',
  ᵽ: 'p',
  ꝑ: 'p',
  ꝙ: 'q',
  ʠ: 'q',
  ɋ: 'q',
  ꝗ: 'q',
  ŕ: 'r',
  ř: 'r',
  ŗ: 'r',
  ṙ: 'r',
  ṛ: 'r',
  ṝ: 'r',
  ȑ: 'r',
  ɾ: 'r',
  ᵳ: 'r',
  ȓ: 'r',
  ṟ: 'r',
  ɼ: 'r',
  ᵲ: 'r',
  ᶉ: 'r',
  ɍ: 'r',
  ɽ: 'r',
  ↄ: 'c',
  ꜿ: 'c',
  ɘ: 'e',
  ɿ: 'r',
  ś: 's',
  ṥ: 's',
  š: 's',
  ṧ: 's',
  ş: 's',
  ŝ: 's',
  ș: 's',
  ṡ: 's',
  ṣ: 's',
  ṩ: 's',
  ʂ: 's',
  ᵴ: 's',
  ᶊ: 's',
  ȿ: 's',
  ɡ: 'g',
  ᴑ: 'o',
  ᴓ: 'o',
  ᴝ: 'u',
  ť: 't',
  ţ: 't',
  ṱ: 't',
  ț: 't',
  ȶ: 't',
  ẗ: 't',
  ⱦ: 't',
  ṫ: 't',
  ṭ: 't',
  ƭ: 't',
  ṯ: 't',
  ᵵ: 't',
  ƫ: 't',
  ʈ: 't',
  ŧ: 't',
  ᵺ: 'th',
  ɐ: 'a',
  ᴂ: 'ae',
  ǝ: 'e',
  ᵷ: 'g',
  ɥ: 'h',
  ʮ: 'h',
  ʯ: 'h',
  ᴉ: 'i',
  ʞ: 'k',
  ꞁ: 'l',
  ɯ: 'm',
  ɰ: 'm',
  ᴔ: 'oe',
  ɹ: 'r',
  ɻ: 'r',
  ɺ: 'r',
  ⱹ: 'r',
  ʇ: 't',
  ʌ: 'v',
  ʍ: 'w',
  ʎ: 'y',
  ꜩ: 'tz',
  ú: 'u',
  ŭ: 'u',
  ǔ: 'u',
  û: 'u',
  ṷ: 'u',
  ü: 'u',
  ǘ: 'u',
  ǚ: 'u',
  ǜ: 'u',
  ǖ: 'u',
  ṳ: 'u',
  ụ: 'u',
  ű: 'u',
  ȕ: 'u',
  ù: 'u',
  ủ: 'u',
  ư: 'u',
  ứ: 'u',
  ự: 'u',
  ừ: 'u',
  ử: 'u',
  ữ: 'u',
  ȗ: 'u',
  ū: 'u',
  ṻ: 'u',
  ų: 'u',
  ᶙ: 'u',
  ů: 'u',
  ũ: 'u',
  ṹ: 'u',
  ṵ: 'u',
  ᵫ: 'ue',
  ꝸ: 'um',
  ⱴ: 'v',
  ꝟ: 'v',
  ṿ: 'v',
  ʋ: 'v',
  ᶌ: 'v',
  ⱱ: 'v',
  ṽ: 'v',
  ꝡ: 'vy',
  ẃ: 'w',
  ŵ: 'w',
  ẅ: 'w',
  ẇ: 'w',
  ẉ: 'w',
  ẁ: 'w',
  ⱳ: 'w',
  ẘ: 'w',
  ẍ: 'x',
  ẋ: 'x',
  ᶍ: 'x',
  ý: 'y',
  ŷ: 'y',
  ÿ: 'y',
  ẏ: 'y',
  ỵ: 'y',
  ỳ: 'y',
  ƴ: 'y',
  ỷ: 'y',
  ỿ: 'y',
  ȳ: 'y',
  ẙ: 'y',
  ɏ: 'y',
  ỹ: 'y',
  ź: 'z',
  ž: 'z',
  ẑ: 'z',
  ʑ: 'z',
  ⱬ: 'z',
  ż: 'z',
  ẓ: 'z',
  ȥ: 'z',
  ẕ: 'z',
  ᵶ: 'z',
  ᶎ: 'z',
  ʐ: 'z',
  ƶ: 'z',
  ɀ: 'z',
  ﬀ: 'ff',
  ﬃ: 'ffi',
  ﬄ: 'ffl',
  ﬁ: 'fi',
  ﬂ: 'fl',
  ĳ: 'ij',
  œ: 'oe',
  ﬆ: 'st',
  ₐ: 'a',
  ₑ: 'e',
  ᵢ: 'i',
  ⱼ: 'j',
  ₒ: 'o',
  ᵣ: 'r',
  ᵤ: 'u',
  ᵥ: 'v',
  ₓ: 'x',
};
String.prototype.urlFormatter = function () {
  var tmp = this.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
    return Latinise.latin_map[a] || a;
  });
  return tmp.replace(/\s/g, '-').toLowerCase();
};

export function getRandomString(length) {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
window.getRandomString = getRandomString;

export function isAnalyticsEnabledInCookies() {
  const gdprAnalyticsCookie = getCookie('gdpr_analytics');
  if (gdprAnalyticsCookie) {
    const { AC } = JSON.parse(gdprAnalyticsCookie) || { AC: 0 };
    return AC === 1;
  }
  return false;
}

export function isValidEmail(x) {
  var atpos = x.indexOf('@');
  var dotpos = x.lastIndexOf('.');
  if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
    return false;
  }
  return true;
}
window.isValidEmail = isValidEmail;

export function get_language() {
  var str = normalize_path(
    window.storybookOverrides?.pathname ?? window.location.pathname
  );
  var urlpart = str.split('/');
  var lstr = urlpart[1]?.toLowerCase();

  const languageArray = Object.values(LanguageCode);
  const isLanguageAvailable = languageArray.includes(lstr);

  if (!isLanguageAvailable) {
    lstr = 'en-gb';
  }

  return lstr;
}
window.get_language = get_language;

export function getContentLanguage() {
  const webSession = window.websession;

  var result = 'EN';
  var languageRegion = get_language();
  for (var x = 0; x < webSession.LanguageList.length; x++) {
    if (languageRegion == webSession.LanguageList[x].LanguageRegion) {
      result = webSession.LanguageList[x].LanguageCode;
      break;
    }
  }
  return result;
}
window.getContentLanguage = getContentLanguage;

export function getLanguageString(str, baseLibrary) {
  try {
    var searchRegExp = /\n/g;
    str = str.replace(searchRegExp, '\\n');
    var textobject = JSON.parse(str);

    // CHANGE KEY TO LOWER CASE
    for (var key in textobject) {
      var lower = key.toLowerCase();
      // check if it already wasn't lowercase
      if (lower !== key) {
        textobject[lower] = textobject[key];
        delete textobject[key];
      }
    }

    var lang = get_language();
    var result = textobject[lang];
    if (result === undefined) {
      lang = getContentLanguage();
      result = textobject[lang];
      if (result === undefined) {
        result = baseLibrary
          ? textobject[baseLibrary]
          : Object.values(textobject)[0] || '';
      }
    }
    return result;
  } catch (x) {
    return str;
  }
}
window.getLanguageString = getLanguageString;

export function getFlagFileName(languageRegionCode) {
  if (/ca-es/i.test(languageRegionCode)) {
    return 'catalonia';
  }
  return languageRegionCode.split('-')[1]?.toLowerCase();
}
window.getFlagFileName = getFlagFileName;

export function getTop() {
  try {
    return top ? top : window;
  } catch (error) {
    console.warn('Top is not accessible ', error);
  }
  return window;
}

export function getGlobalVariableEnv(ghApiAddress) {
  if (ghApiAddress.includes('myengine.goodhabitz.com')) {
    return 'prod';
  } else if (
    ghApiAddress.includes('api-acceptance.goodhabitz.com') ||
    ghApiAddress.includes('myengine.staging.obi.gh-platform.cloud')
  ) {
    return 'staging';
  } else if (ghApiAddress.includes('api-test.goodhabitz.com')) {
    return 'test';
  } else {
    return null;
  }
}

export function getFlagByActivityType(activityType, flags) {
  if (!window.newActivityFlagConfig || !activityType) {
    return null;
  }

  const flagName = window.newActivityFlagConfig[activityType];
  if (flagName) {
    return flags[flagName];
  }
  return null;
}

export function getGlobalVariableByEnv(name) {
  if (window.ghapi && window[name]) {
    const globalVariableEnv = getGlobalVariableEnv(window.ghapi);
    return window[name][globalVariableEnv];
  }
  return null;
}

function redirectToLocation(location) {
  try {
    // TODO: Check if top is needed. Ticket number: LJ-140
    getTop().document.location = location;
  } catch (error) {
    console.warn('Location set from window.top has failed');
    document.location = location;
  }
}

export function redirectToLogin() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lb = urlParams.get('lb');

  if (lb) {
    sessionStorage.setItem(
      'CoursePageUrl',
      `/${get_language()}/personal-courses.html?lb=${lb}`
    );
  }
  redirectToLocation('/' + get_language() + '/login.html');
}

export function redirectToOtherDevice() {
  redirectToLocation('/' + get_language() + '/otherDevice.html');
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();

  if (cname === 'gdpr' || cname === 'gdpr_analytics') {
    document.cookie =
      cname +
      '=' +
      cvalue +
      '; ' +
      expires +
      `;path=/;domain=${
        ['localhost', '.gh-platform.cloud'].some((host) =>
          window.location.hostname.endsWith(host)
        )
          ? window.location.hostname
          : '.goodhabitz.com'
      };SameSite=None; secure`;
  } else {
    document.cookie =
      cname +
      '=' +
      encodeURIComponent(cvalue) +
      ';' +
      expires +
      `;path=/;domain=${
        window.location.hostname === 'localhost'
          ? 'localhost'
          : '.goodhabitz.com'
      }; secure`;
  }
}
window.setCookie = setCookie;

export function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      if (cname === 'gdpr' || cname === 'gdpr_analytics') {
        c = c.substring(1);
      } else {
        c = decodeURIComponent(c.substring(1));
      }
    }
    if (c.indexOf(name) == 0) {
      if (cname === 'gdpr') {
        return c.substring(name.length, c.length);
      } else {
        return decodeURIComponent(c.substring(name.length, c.length));
      }
    }
  }
  return '';
}
window.getCookie = getCookie;

export function getUserDetails() {
  const user = window.websession.CurrentUser;
  const isUserLoggedIn = user && user.Id !== -1;

  if (isUserLoggedIn) {
    const { ExternalPersonReference, CompanyId, Country } = user;

    return {
      userRefId: ExternalPersonReference,
      companyId: CompanyId,
      country: Country,
    };
  }
  return {};
}

//helper functions
function append_authorization_header(request, isBackOffice) {
  const authToken = isBackOffice
    ? sessionStorage.getItem('bo_token')
    : getToken();

  if (authToken) {
    request.setRequestHeader('Authorization', `Bearer ${authToken}`);
  }
}

export function normalize_path(str) {
  return str.replace(new RegExp('//', 'g'), '/');
}
window.normalize_path = normalize_path;

function getParentWindow() {
  try {
    var isSaveHidden = top.hidesave;
    return isSaveHidden ? top : window;
  } catch (error) {
    console.warn('Top is not accessible ', error);
  }
  return window;
}

var parentWindow = getParentWindow();

export function xdr(url, method, data, callback, errback, finallyback) {
  var req;
  var bo = false;
  var isApi = url.startsWith(window.ghapi);
  var isLogin =
    isApi && url.toLowerCase().includes('/api/token') && method === 'POST';
  var isLmsLogin =
    document.location.pathname.includes('/login_lti.html') ||
    document.location.pathname.includes('/lms-login');
  var loginOrigin = isLmsLogin
    ? 'LTI'
    : getWebsession()?.CurrentUser?.LoginOrigin;

  if (
    normalize_path(document.location.pathname).split('/')[2]?.toLowerCase() ==
    'bo'
  ) {
    bo = true;
  }

  req = new XMLHttpRequest();
  if ('withCredentials' in req) {
    req.open(method, url, true);
    req.setRequestHeader('Content-Type', 'Application/json');
    req.setRequestHeader('Accept', 'Application/json');

    req.onerror = errback;
    append_authorization_header(req, bo);
    req.onreadystatechange = function () {
      if (req.readyState === 4) {
        if (isApi && req.status === 401 && loginOrigin === 'WEB' && !isLogin) {
          addError(new Error('401 received. Redirect to login occurred'), {
            loginOrigin,
            request: {
              method,
              url,
            },
          });
          setTimeout(() => {
            window.location.href = edit_url('/client/logoff.html');
          }, 100);
        } else if (req.status >= 200 && req.status < 400) {
          parentWindow?.hidesave();
          if (callback) callback(req.responseText);
        } else {
          parentWindow?.hidesave();
          if (errback) errback(req.status);
        }
      }
      if (finallyback) finallyback(req);
    };
    req.send(data);
  }
}
window.xdr = xdr;

export const xdrAsync = (url, method, data, callback, errback) =>
  new Promise((resolve, reject) => {
    const onFulfill = (...args) => {
      if (callback) callback(...args);
      resolve();
    };

    const onReject = (...args) => {
      if (errback) errback(...args);
      reject(...args);
    };

    xdr(url, method, data, onFulfill, onReject);
  });

export async function fetchJson({ url, method = 'GET', data = undefined }) {
  const stringResponse = await new Promise((resolve, reject) => {
    xdr(window.ghapi + url, method, data, resolve, reject);
  });
  if (stringResponse) {
    return JSON.parse(stringResponse);
  } else {
    return;
  }
}

export async function fetchJsonFromWebsite({
  url,
  method = 'GET',
  data = undefined,
}) {
  const stringResponse = await new Promise((resolve, reject) => {
    xdr(window.origin + url, method, data, resolve, reject);
  });
  if (stringResponse) {
    return JSON.parse(stringResponse);
  } else {
    return;
  }
}

export async function fetchBlob({ url, method = 'GET', data = undefined }) {
  const stringResponse = await new Promise((resolve, reject) => {
    xdrBlob(window.ghapi + url, resolve, method, data, reject);
  });
  if (stringResponse) {
    return stringResponse;
  } else {
    return;
  }
}

export function xdrBlob(url, callback, method, data, errback) {
  var req;
  var bo = false;
  if (
    normalize_path(document.location.pathname).split('/')[2].toLowerCase() ==
    'bo'
  ) {
    bo = true;
  }
  req = new XMLHttpRequest();
  if ('withCredentials' in req) {
    req.open(method, url, true);
    req.setRequestHeader('Content-Type', 'Application/json');
    req.responseType = 'blob';
    append_authorization_header(req, bo);
    req.onreadystatechange = function () {
      if (req.readyState === 4) {
        if (req.status >= 200 && req.status < 400) {
          parentWindow?.hidesave();
          callback(req.response);
        } else {
          parentWindow?.hidesave();
          if (errback !== undefined) {
            errback(req.status);
          } else {
            alert(req.status);
          }
        }
      }
    };
    req.send(data);
  }
}
window.xdrBlob = xdrBlob;

export function serialize(form) {
  if (!form || form.nodeName !== 'FORM') {
    return;
  }
  var frm = {};
  var i, j;
  for (i = form.elements.length - 1; i >= 0; i = i - 1) {
    if (form.elements[i].name === '') {
      continue;
    }
    switch (form.elements[i].nodeName) {
      case 'INPUT':
        switch (form.elements[i].type) {
          case 'text':
          case 'email':
          case 'hidden':
          case 'password':
          case 'button':
          case 'reset':
          case 'date':
          case 'number':
          case 'submit':
            frm[form.elements[i].name.replace(/-/g, '')] = encodeURIComponent(
              form.elements[i].value
            );
            break;
          case 'checkbox':
          case 'radio':
            if (form.elements[i].checked) {
              frm[form.elements[i].name.replace(/-/g, '')] = encodeURIComponent(
                form.elements[i].value
              );
            }
            break;
        }
        break;
      case 'file':
        break;
      case 'TEXTAREA':
        frm[form.elements[i].name.replace(/-/g, '')] = encodeURIComponent(
          form.elements[i].value
        );
        break;
      case 'SELECT':
        switch (form.elements[i].type) {
          case 'select-one':
            frm[form.elements[i].name.replace(/-/g, '')] = encodeURIComponent(
              form.elements[i].value
            );
            break;
          case 'select-multiple':
            for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
              if (form.elements[i].options[j].selected) {
                frm[form.elements[i].name.replace(/-/g, '')] =
                  encodeURIComponent(form.elements[i].options[j].value);
              }
            }
            break;
        }
        break;
      case 'BUTTON':
        switch (form.elements[i].type) {
          case 'reset':
          case 'submit':
          case 'button':
            frm[form.elements[i].name.replace(/-/g, '')] = encodeURIComponent(
              form.elements[i].value
            );
            break;
        }
        break;
    }
  }
  return frm;
}
window.serialize = serialize;

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&').toLowerCase(); // This is just to avoid case sensitiveness for query parameter name
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
window.getParameterByName = getParameterByName;

function editUrl(url) {
  var lc = get_language();
  if (url.toLowerCase().indexOf('/clientbeta/') === -1) {
    url = url.replace('/client/', '/' + lc + '/');
  } else {
    url = url.toLowerCase().replace('/clientbeta/', '/' + lc + '/beta/');
  }
  return url;
}

export function getNewCourseAccessUrl(courseId, courseName, documentName) {
  const courseSlug =
    documentName !== '.html' ? documentName : `${courseName}.html`;
  if (getGlobalVariableEnv(window.ghapi) === 'test') {
    return (
      editUrl('/client/online-course/') +
      courseId +
      '/' +
      courseSlug.replace('.html', '')
    );
  }
  return (
    editUrl('/client/courses/') +
    courseId +
    '-' +
    courseSlug.replace('.html', '')
  );
}
window.getNewCourseAccessUrl = getNewCourseAccessUrl;

export function getCourseAccessUrl(courseId, courseName, documentName) {
  const courseSlug =
    documentName !== '.html' ? documentName : `${courseName}.html`;
  return editUrl('/client/online-course/') + courseId + '/' + courseSlug;
}
window.getCourseAccessUrl = getCourseAccessUrl;

export function isExpiredSlimCourse(course) {
  return course?.NodeTypeId !== 13 && course?.PublicationStatus === 1;
}
window.isExpiredSlimCourse = isExpiredSlimCourse;

export function unescapeHtml(text) {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.documentElement.textContent;
}

export function displayInt(val) {
  var result = val.toString();
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(result)) {
    if (parentWindow?.bo_lang) {
      result = result.replace(
        rgx,
        '$1' +
          unescapeHtml(parentWindow?.bo_lang.global.numeric_thousand_divider) +
          '$2'
      );
    } else {
      const separator =
        lang?.generic?.thousands_separator &&
        lang.generic.thousands_separator !== ' '
          ? unescapeHtml(lang.generic.thousands_separator)
          : '.';
      result = result.replace(rgx, '$1' + separator + '$2');
    }
  }
  return result;
}
window.displayInt = displayInt;

export function querystringToJson(str) {
  let result = '{';
  const arg = str.split('&');
  for (var i = 0; i < arg.length; i++) {
    if (i != 0 && i < arg.length) {
      result += ',';
    }
    const kvp = arg[i].split('=');
    result += '"' + kvp[0] + '":"' + kvp[1] + '"';
  }
  result += '}';
  return result;
}
window.querystringToJson = querystringToJson;

export function isnull(input, defaultval) {
  defaultval = defaultval || '';
  var output = input || defaultval;
  return output;
}
window.isnull = isnull;

export function downloadLegalDocument(d) {
  var typeID = d.TypeId;
  var fileName =
    getLanguageString(d.TypeName).replace(/[<>:"\/\\|?*]+/g, '_') +
    '_' +
    d.VersionMajor +
    '_' +
    d.VersionMinor +
    '.pdf';
  xdrBlob(
    ghapi + '/API/LegalDocumentDownload/' + typeID,
    function (blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else if (
        navigator.userAgent.match('CriOS') ||
        navigator.userAgent.match('FxiOS')
      ) {
        var reader = new FileReader();
        reader.onloadend = function () {
          window.open(reader.result);
        };
        reader.readAsDataURL(blob);
      } else {
        if (
          typeof safari !== 'undefined' &&
          navigator.userAgent.indexOf('iPhone') !== -1
        ) {
          var reader = new window.FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;

            var a = document.createElement('a');
            a.href = encodeURI(base64data);
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);

            window.open(encodeURI(base64data));
            setTimeout(function () {
              a.parentNode.removeChild(a);
            }, 1500);
          };
        } else {
          var url = URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            a.parentNode.removeChild(a);
          }, 1500);
        }
      }
    },
    'GET',
    null
  );
}
window.downloadLegalDocument = downloadLegalDocument;

export function renderExcel(data, fileName) {
  var fn = typeof fileName === 'undefined' ? 'export.xlsx' : fileName;

  xdrBlob(
    ghapi + '/API/ExportToExcel',
    function (blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fn);
        try {
          parentWindow?.hidesave();
        } catch (e) {}
      } else {
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fn;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        try {
          parentWindow?.hidesave();
        } catch (e) {}
        setTimeout(function () {
          a.parentNode.removeChild(a);
        }, 1500);
      }
    },
    'POST',
    JSON.stringify(data)
  );
}
window.renderExcel = renderExcel;

export function renderCSV(csvData, fileName) {
  var fn = typeof fileName === 'undefined' ? 'export.csv' : fileName;

  var buffer = csvData.join('\n');
  var blob = new Blob(['\ufeff', buffer], { type: 'text/csv' });
  var link = document.createElement('a');

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fn);
    try {
      parentWindow?.hidesave();
    } catch (e) {}
  } else {
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = fn;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    try {
      parentWindow?.hidesave();
    } catch (e) {}
    setTimeout(function () {
      a.parentNode.removeChild(a);
    }, 1500);
  }
}
window.renderCSV = renderCSV;

export function saveBlobToFile(blob, fileName) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    if (
      typeof safari !== 'undefined' &&
      navigator.userAgent.indexOf('iPhone') !== -1
    ) {
      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;

        var a = document.createElement('a');
        a.href = encodeURI(base64data);
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);

        window.open(encodeURI(base64data));
        setTimeout(function () {
          a.parentNode.removeChild(a);
        }, 1500);
      };
    } else {
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        a.parentNode.removeChild(a);
      }, 1500);
    }
  }
}
window.saveBlobToFile = saveBlobToFile;

export function showsave(txt) {
  var bg = document.createElement('DIV');
  bg.id = 'load_back';
  bg.style.position = 'fixed';
  bg.style.left = '0px';
  bg.style.top = '0px';
  bg.style.width = '100%';
  bg.style.height = '100%';
  bg.style.backgroundColor = 'rgba(0,0,0,0.0)';
  bg.innerHTML = '';
  bg.style.zIndex = '19998';
  bg.style.transition = 'all 0.1s ease-in-out';
  var _p = parentWindow?.document?.getElementsByTagName('BODY')[0];
  _p.appendChild(bg);
  setTimeout(function () {
    bg.style.backgroundColor = 'rgba(0,0,0,0.3)';
  }, 10);
  if (txt != '') {
    var div = document.createElement('DIV');
    div.id = 'load_front';
    div.className = 'loadframe';
    var title = document.createElement('DIV');
    title.className = 'load_title';
    if (parentWindow?.bo_lang) {
      title.innerHTML = parentWindow?.bo_lang.global.load_title_txt;
    } else {
      title.innerHTML = lang.generic.load_title_txt;
    }
    div.appendChild(title);
    var animation = document.createElement('DIV');
    animation.style = 'margin:16px auto;width:100%;text-align:center;';
    animation.innerHTML = '<i class="loadingicon fa fa-spinner fa-spin"></i>';
    div.appendChild(animation);
    var loadtxt = document.createElement('DIV');
    loadtxt.className = 'load_txt';
    loadtxt.innerHTML = txt;
    div.appendChild(loadtxt);
    div.style.zIndex = '19999';
    _p.appendChild(div);
  }
  return div;
}
window.showsave = showsave;

export function hidesave() {
  //setTimeout(function () {
  const f = parentWindow?.document?.getElementById('load_front');
  if (f) {
    f.parentElement.removeChild(f);
  }
  const b = parentWindow?.document?.getElementById('load_back');
  if (b) {
    b.style.backgroundColor = 'rgba(0,0,0,0.0)';
    try {
      b.parentElement.removeChild(b);
    } catch (x) {}
  }
}
window.hidesave = hidesave;

export function checkIsFavoritedCourse(courseId) {
  let isFavorited = false;

  const favoritedCourseList = websession.CourseListOrder.find(function (clo) {
    return (
      clo.Name ===
      Components.Slider.SliderType[Components.Slider.SliderType.Fav].toString()
    );
  });
  if (favoritedCourseList) {
    isFavorited = favoritedCourseList.Course.some((item) => item === courseId);
  }

  return isFavorited;
}
window.checkIsFavoritedCourse = checkIsFavoritedCourse;

const removeLeadingSlash = (str) => str.replace(/^\//, '');

export function mergeArrays(origin = [], override = []) {
  return origin.concat(override).reduce((acc, item) => {
    const existingItem = acc.find(
      (x) => removeLeadingSlash(x.page) === removeLeadingSlash(item.page)
    );

    if (existingItem) {
      existingItem.data = Object.assign({}, existingItem.data, item.data);
    } else {
      acc.push({
        page: removeLeadingSlash(item.page),
        data: item.data,
      });
    }

    return acc;
  }, []);
}

export function createSlug(text) {
  return text
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\_/g, '-')
    .replace(/\-\-+/g, '-')
    .replace(/\-$/g, '');
}
window.createSlug = createSlug;
